import gql from "graphql-tag";
import {
  FRAGMENT_MEDIA_ITEM,
  FRAGMENT_NEIGHBORHOOD_DETAILS,
} from "./fragments.js";

export const GET_NEIGHBORHOOD = gql`
  ${FRAGMENT_MEDIA_ITEM}
  ${FRAGMENT_NEIGHBORHOOD_DETAILS}
  query GetNeighborhood($uri: ID!) {
    neighborhood(id: $uri, idType: URI) {
      basePrice
      databaseId
      title
      uri
      slug
      types
      neighborhood {
        address1
        address2
        city
        description
        hours
        hubspotValue
        iframeSitemap
        latitude
        legal
        longitude
        neighborhoodArea
        phone
        siteMapPdfEPub
        state
        status
        zip
        hideCommunity
        alternatingContent {
          button
          buttonText
          description
          fieldGroupName
          id
          link
          title
          videoEmbed
          image {
            ...MediaItemFragment
          }
        }
        neighborhoodFeatures {
          category
          description
          fieldGroupName
          title
        }
        popularNeighborhoods {
          ...NeighborhoodDetailsFragment
        }
        saleTeamMembers {
          name
          image {
            ...MediaItemFragment
          }
        }
        thumbnail {
          ...MediaItemFragment
        }
        upcomingEvent {
          ... on Event {
            id
            databaseId
            title
            uri
          }
        }
      }
    }
    models(last: 500) {
      nodes {
        databaseId
        id
        title
        uri
        collection {
          collection
          fieldGroupName
        }
        modelDetails {
          elevations {
            ...MediaItemFragment
          }
          neighborhood {
            ... on Neighborhood {
              id
              slug
              title
            }
          }
          floorplan {
            ... on Plan {
              databaseId
              id
              slug
              title
              floorplanDetails {
                bathroomsMax
                bathroomsMin
                bedroomsMax
                bedroomsMin
                collection
                displayTitle
                finishedSqrFt
                garage
                garageMin
                mainSqrFt
                features {
                  feature
                }
              }
            }
          }
        }
        productStatus {
          enableSnipeLink
          fieldGroupName
          sectionId
          statusCustom
          statusMessage
        }
      }
    }
    plans(last: 500) {
      nodes {
        databaseId
        id
        title
        uri
        floorplanDetails {
          basePrice
          bathroomsMax
          bathroomsMin
          bedroomsMax
          bedroomsMin
          collection
          displayTitle
          finishedSqrFt
          garage
          garageMin
          mainSqrFt
          elevations {
            ...MediaItemFragment
          }
          features {
            feature
          }
          neighborhood {
            ... on Neighborhood {
              databaseId
              id
              slug
              title
            }
          }
          planPriceByCommunity {
            neighborhood {
              ... on Neighborhood {
                title
                databaseId
              }
            }
            price
          }
        }
        productStatus {
          enableSnipeLink
          fieldGroupName
          sectionId
          statusCustom
          statusMessage
        }
      }
    }
    neighborhoods {
      nodes {
        ...NeighborhoodDetailsFragment
      }
    }
    homes(last: 500) {
      nodes {
        databaseId
        slug
        title
        uri
        collection {
          collection
          fieldGroupName
        }
        qmiHomeDetails {
          address1
          address2
          bathrooms
          bedrooms
          finishedSqrFt
          garage
          latitude
          longitude
          mainSqrFt
          price
          elevations {
            altText
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
            sourceUrl
          }
          floorplan {
            ... on Plan {
              databaseId
              id
              slug
              title
              floorplanDetails {
                bathroomsMax
                bathroomsMin
                bedroomsMax
                bedroomsMin
                collection
                displayTitle
                finishedSqrFt
                garage
                garageMin
                mainSqrFt
                features {
                  feature
                }
              }
            }
          }
          neighborhood {
            ... on Neighborhood {
              id
              slug
              title
              uri
            }
          }
        }
        productStatus {
          enableSnipeLink
          fieldGroupName
          sectionId
          statusCustom
          statusMessage
        }
      }
    }
    locations(last: 500) {
      nodes {
        databaseId
        id
        title
        uri
        locationDetails {
          phone
          latitude
          longitude
          state
          zip
          city
          address2
          address1
          hours
          image {
            ...MediaItemFragment
          }
          neighborhood {
            ...NeighborhoodDetailsFragment
          }
        }
      }
    }
  }
`;
